import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import styled from "styled-components"
import ServicesImages from "./ServicesImages"

const query = graphql`
  {
    images: allFile(
      filter: { relativeDirectory: { eq: "pages/services/services-stoves" } }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(quality: 64, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

const ServicesStoves = () => {
  const data = useStaticQuery(query)

  return (
    <Wrapper className="section-center">
      <article className="text-container">
        <p>
          Solid fuel and gas stove is the most efficient way to provide maximum
          heat with minimum emissions, which is why stoves becoming the perfect
          solution for any home. With our wide range of stoves, we can ensure
          that even for a modern home we can find an elegant stove to complement
          your interior.
        </p>
        <p>
          Thanks to our experience team, we provide the complete flue inspection
          and when necessary make all adjustments to your chimney system to
          ensure that you can safely use your stove in most efficient way. All
          works will be carried out by a fully qualified “Gas Safe” engineer or
          Hetas registered engineer for wood or solid fuel stoves.
        </p>
        <p>
          In order to respond to your requirements we provide a full service,
          from site-survey, to the advice on the best options for your interior.
          We believe that customer service doesn't end once you have your stove
          installed in your home, that's why we always we offer additional fire
          maintenance services to all of our customers.
        </p>
        <div className="back-to-button">
          <Link to={"/services"} className="btn btn--small">
            Back to services
          </Link>
        </div>
      </article>
      <aside className="image-container">
        <ServicesImages data={data} />
      </aside>
    </Wrapper>
  )
}

export default ServicesStoves

const Wrapper = styled.main`
  .image-container {
    margin-bottom: 2rem;
    height: 100%;
  }
  .text-container p {
    line-height: 2;
  }
  @media screen and (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    column-gap: 4rem;

    .text-container {
      grid-column: 1 / span 6;
    }

    .image-container {
      grid-column: 7 / -1;
    }
  }
`
