import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import Img from "gatsby-image"
import styled from "styled-components"
import { sortQueryData } from "../../helpers/query"

const ServicesImages = ({ data }) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  const images = sortQueryData(data).map(item => {
    return item.node.childImageSharp.fluid.src
  })
  const thumbnails = sortQueryData(data).map(item => {
    return item.node.childImageSharp.fluid
  })
  const imageCount = thumbnails.length
  const getImageHeight = () => {
    if (imageCount <= 3) {
      return 250
    }

    return 1000 / imageCount
  }

  if (thumbnails.length === 1) {
    return (
      <SingleWrapper>
        <Img fluid={thumbnails[0]} className="single-img" />
      </SingleWrapper>
    )
  }

  return (
    <GalleryWrapper imageHeight={getImageHeight()}>
      {thumbnails.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            setPhotoIndex(index)
            setIsOpen(true)
          }}
        >
          <Img fluid={item} className="gallery-img" />
        </div>
      ))}

      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          enableZoom={false}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </GalleryWrapper>
  )
}

export default ServicesImages

const GalleryWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill);
  column-gap: 1.25rem;
  row-gap: 1.25rem;
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

  .gallery-img {
    height: ${({ imageHeight = 200 }) => imageHeight}px;
    cursor: pointer;
    transition: var(--transition);
  }
  .gallery-img:hover {
    opacity: 0.75;
  }
`
const SingleWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fill);
  column-gap: 1.25rem;
  row-gap: 1.25rem;
  @media screen and (min-width: 576px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }

  .single-img {
    height: 500px;
  }
`
