import React from "react"
import SectionTitle from "../../components/SectionTitle"
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import PageWrapper from "../../components/layout/PageWrapper"
import ServicesStoves from "../../components/content/ServicesStoves"

const GasFireplaceInstallers = () => (
  <Layout>
    <SEO
      title="Wood burning stoves installers"
      description="Gas, Solid Fuel And Bio Fuel Fireplaces Design and Installation"
    />
    <PageWrapper>
      <SectionTitle
        title="Stoves installation"
        strapline="Gas, solid fuel and bio fuel stoves"
      />
      <ServicesStoves />
    </PageWrapper>
  </Layout>
)

export default GasFireplaceInstallers
